import { getContext } from '~/functions/getContext';
import type { PosContext } from '~/types';

const posContext = useState<PosContext>('pos-context');

export async function usePosContext() {
    if (posContext.value) {
        return posContext;
    }

    const res = await getContext();

    posContext.value = res;

    return posContext;
}
