import type { PosContext } from '~/types';

export async function getContext(): Promise<PosContext> {
    if (process.server) {
        throw new Error('`getContext.ts` must be run client side.');
    }

    // IMPORTANT: Must always run client side.
    const response = await fetch(
        'http://127.0.0.1:8888/api/v1/Scripts/getContext',
        {
            headers: {
                Accept: 'application/json',
            },
        },
    );

    const data: PosContext = await response.json();

    return data;
}
